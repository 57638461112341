<template>
  <div class="main">
    <BannerCustom/>
    <div class="sign_up" :class="{ step2: step === 2 }">
      <div class="sign_up__wrap">
        <div class="sign_up__content">
          <div class="sign_up__main" v-if="step === 1">
            <div class="img-mb"><img loading="lazy" src="@/assets/images/register/frame-mb.webp" /></div>
            <div class="contents">
              <h1>{{ getTranslatedText("REGISTER") }}</h1>
              <p>{{ getTranslatedText("Register by filling in the below fields.") }}</p>
              <div class="sign_up__form">
                <FormCommon
                  :formItem="formItem"
                  :dataForm="dataForm"
                  @update-form-data="updateFormData"
                  @onToggleShowPassword="onToggleShowPassword"
                >
                  <b-input-group-prepend
                    slot="propend-phone-number"
                    class="phone-code"
                  >
                    <button
                      class="show-dropdown"
                      type="button"
                      @click="showDropDow(1)"
                    >
                    <div class="flag-group">
                      <img
                        loading="lazy"
                        class="user-avatar"
                        :src="require(`@/assets/images/flags/${dataForm.flag}`)"
                        :alt="dataForm.flag"
                      />
                    </div>
                      <span class="name-country">{{ dataForm.calling_code }}</span>
                    </button>
                    <b-dropdown ref="select_phone_code">
                      <b-form-input
                        v-model="textSearchPhoneNumber"
                        :placeholder="getTranslatedText('search')"
                        @input="onChangeFilterPhoneNumbers"
                      ></b-form-input>
                      <b-dropdown-item
                        @click="selectPhoneCode(item)"
                        v-for="(item, index) in phoneNumbersFilter"
                        :key="index"
                      >
                        <div>
                          <div class="flag-group">
                            <img
                              loading="lazy"
                              class="user-avatar"
                              :src="require(`@/assets/images/flags/${item.flag}`)"
                              :alt="item.flag"
                            />
                          </div>
                          <span class="name-country">{{ item.name }}</span>
                          ({{ item.number }})
                        </div>
                      </b-dropdown-item>
                    </b-dropdown>
                    <img
                      loading="lazy"
                      class="dropdown-phone-icon"
                      src="@/assets/images/personal-detail/dropdown-black.png"
                    />
                  </b-input-group-prepend>
                   <template slot="birthDate">
                      <span class="obligatory-date">*</span>
                      <DateDropdown
                          :default="dataForm.dob"
                          label="date Birth"
                          :setDefault="false"
                          min="1920"
                          :max="maxYearBirth"
                          @input="updateDate"
                          :textInvalidFeedback="textInvalidFeedbackDate"
                          :isHideLabelItem="true"
                          :showPlaceholder="true"
                          textDay="Day"
                          textMonth="Month"
                          textYear="Year"
                          :required="true"
                      />
                  </template>
                </FormCommon>
                <div class="guide-pass">
                  <p :class="checkValidPass(isInvalidLengthPass)">{{ getTranslatedText("Must be between 8 and 30 characters long.") }}</p>
                  <p :class="checkValidPass(isInvalidCapitalPass)">{{ getTranslatedText("Must have a Capital Letter.") }}</p>
                  <p :class="checkValidPass(isInvalidSpecialChar)">{{ getTranslatedText("Must have a number and special character.") }}</p>
                </div>
                <div class="sign_up__policy">
                  <label class="block-checkbox"
                    >{{
                      getTranslatedText(
                        "I confirm that I have read and accepted the"
                      )
                    }}
                    <span
                      ><a href="/terms-and-conditions" target="_blank">{{
                        getTranslatedText("Terms & Conditions")
                      }}</a></span
                    >
                    {{ getTranslatedText("and")
                    }}<span
                      ><a href="/terms-and-conditions" target="_blank"> {{
                        getTranslatedText("Privacy Policy")
                      }}</a></span
                    >
                    <span class="obligatory">*</span>
                    <input type="checkbox" id="checkbox" v-model="isCheckReadTC" />
                    <span class="checkmark"></span>
                  </label>
                  <label class="block-checkbox"
                    >{{
                      getTranslatedText(
                        "I would like to receive offers, newsletters, promotions and other marketing communications"
                      )
                    }}
                    <input
                      type="checkbox"
                      id="checkbox2"
                      v-model="isReceiveInformation"
                    />
                    <span class="checkmark"></span>
                  </label>
                </div>
                <div class="sign_up__btn">
                  <ButtonCustom
                    classBtn="sign-up-btn"
                    typeBtn="button"
                    type="primary"
                    text="REGISTER"
                    :isLoading="isLoadingButtonRes"
                    @onClickButton="onSubmitRegister()"
                  />
                </div>
              </div>
              <!-- <div class="sign_up__Social">
                <div class="sign_up__or">
                  <div class="line"></div>
                  <span>{{ getTranslatedText("or") }}</span>
                  <div class="line"></div>
                </div>
                <SocialSignUp
                  :isShowFbSocial="false"
                  textButtonFB="Register with Facebook"
                  textButton="Register with Google"
                  @onLogin="onSubmitForm"
                  :isLoadingButton="isLoadingButtonRes"
                />
              </div> -->
            </div>
            <div class="img-dt"><img loading="lazy" src="@/assets/images/register/frame.webp" /></div>
          </div>
          <div class="sign_up__congratulation" v-if="step === 3 && isNewPage">
            <div class="img-dt"><img loading="lazy" src="@/assets/images/register/success.webp" /></div>
            <div class="contents">
              <h4>{{ getTranslatedText("Congratulations!") }}</h4>
              <p>
                {{
                  getTranslatedText(
                    "You have successfully created your O! Millionaire account. Now it’s time to participate in our Green Initiative, Oasis Park."
                  )
                }}
              </p>
              <ButtonCustom
                classBtn="buy-now-btn"
                typeBtn="button"
                type="primary"
                text="Buy Now"
                @onClickButton="redirectRouter('/numbers')"
              />
            </div>
            <div class="img-mb"><img loading="lazy" src="@/assets/images/register/success-mb.webp" /></div>
          </div>
          <Toast :title="toastData.message" :type="toastData.type" />
        </div>
      </div>
    </div>
    <div v-if="step === 2">
    <ConfirmOTP
        :dataSource="dataForm"
        @sendOTP="getTokenRecaptcha('resend')"
        @onSubmitForm="onSubmitSignUp"
        @onClickGoBack="onClickGoBack()"
        methodsChangeInfo="phone"
        :text="getTranslatedText('enter otp')"
        :textBtnContinue="getTranslatedText('reset password')"
        :isLoadingButton="isLoadingButtonOtp"
        :isLoadingButtonRecaptcha="isLoadingButtonRecapt"
    />
    </div>
    <button ref="recaptchaSignUp" id="recaptcha-main" class="g-recaptcha" :data-sitekey="sitekey" data-action='submit'></button>
    <ConfirmRecaptcha
      id="modalRecaptchaSignup"
      idRecaptcha="recaptcha-signup"
      :sitekey="sitekey"
      :isLoadingButton="isLoadingButtonRecapt"
      @onSubmitRecaptcha="sendOTPForPhone"
    />
  </div>
</template>
<script>
import LanguageMixin from "@/mixins/language/language-mixin";
import FormCommon from "@/components/common/form";
import ButtonCustom from "@/components/common/button";
import BannerCustom from "@/components/common/banner";
import ConfirmRecaptcha from "@/components/common/confirm-recaptcha";

import { phoneNumbers } from "@/resources/phoneNumber";
import defaultCountryCodeMixin from "@/mixins/default-country-code/default-country-code-mixin";
import {
  filterFullTextSearch,
  isStrEmpty,
  isArrayEmpty,
  redirectPathApp,
  validateEmail
} from "@/components/common/utils";
import { isPossiblePhoneNumber, isValidPhoneNumber } from "libphonenumber-js";
import parseMax from "libphonenumber-js/max";
import AuthService from "@/services/auth.service";
import CountriesService from "@/services/countries.service";
import Toast from "@/components/common/toast/Toast.vue";
// import SocialSignUp from "@/components/common/socialLogin";
import ConfirmOTP from "@/views/mobile/authenticate/ConfirmOTP";
import DateDropdown from "@/components/common/date-dropdown/DateDropdown.vue";
import moment from "moment";

const SITEKEY = process.env.VUE_APP_SITE_KEY_RECAPTCHA;
const LIMIT_PASSWORD = process.env.VUE_APP_LIMIT_PASSWORD;
const RECAPTCHA_TYPE = process.env.VUE_APP_RECAPTCHA_TYPE;

let scriptTag, scriptTag1, scriptTag2

export default {
  mixins: [LanguageMixin, defaultCountryCodeMixin],
  components: {
    FormCommon,
    ButtonCustom,
    BannerCustom,
    Toast,
    // SocialSignUp,
    ConfirmOTP,
    ConfirmRecaptcha,
    DateDropdown
  },
  data() {
    return {
      sitekey: SITEKEY,
      recaptchaType: RECAPTCHA_TYPE,
      messageRecaptcha: "",
      maxYearBirth: "0",
      maxDate: "",
      textInvalidFeedbackDate: "",
      isInvalidLengthPass: null,
      isInvalidCapitalPass: null,
      isInvalidSpecialChar: null,
      dataForm: {
        calling_code: "+968",
        code: "+968",
        phone: "",
        flag: "Flag_of_Oman.svg",
        full_name: "",
        password: "",
        dob: "",
        nationality: "",
        gender: "",
        email: "",
      },
      formItem: [
        {
          type: "input",
          typeInput: "text",
          name: "full_name",
          label: "full name",
          for: "input-full-name",
          id: "input-full-name",
          placeholder: "Eg, your text here",
          idFormGroup: "register-fullName",
          state: null,
          textInvalid: "Please enter full name",
          required: true,
        },
        {
          type: "input-group",
          typeInput: "number",
          placeholder: "",
          name: "phone",
          label: "Mobile Number",
          for: "input-phone",
          id: "input-phone",
          prepend: "code",
          nameSlotInputPrepend: "propend-phone-number",
          state: null,
          textInvalid: "Please enter mobile number",
          useSuggestedPass: "tel",
          required: true,
        },
        // {
        //   type: "select",
        //   label: `gender`,
        //   name: "gender",
        //   required: true,
        //   for: "input-gender-signup",
        //   id: "input-gender-signup",
        //   idGroup: "input-group-gender",
        //   state: null,
        //   textInvalid: "Please enter gender",
        //   options: [
        //     {text: this.getTranslatedText("male"), value: "male"},
        //     {
        //         text: this.getTranslatedText("female"),
        //         value: "female",
        //     },
        //     {
        //         text: this.getTranslatedText("not specify"),
        //         value: "not-specify",
        //     },
        //   ],
        // },
        {
          type: "input",
          typeInput: "email",
          label: "email",
          name: "email",
          for: "input-email",
          id: "input-email",
          useSuggestedPass: "email",
          state: null,
        },
        {
          type: "select",
          label: `nationality`,
          name: "nationality",
          required: true,
          for: "input-nationality-signup",
          id: "input-nationality-signup",
          idGroup: "input-group-nationality",
          state: null,
          textInvalid: "Please enter nationality",
          options: [],
        },
        {
          type: "slot",
          nameSlot: "birthDate",
          state: null,
        },
        {
          type: "input",
          typeInput: "password",
          typeShow: "password",
          showIconEye: true,
          label: "password",
          placeholder: "password",
          name: "password",
          required: true,
          useSuggestedPass: "new-password",
          state: null,
          for: "input-password",
          id: "input-password",
          textInvalid: "",
          txtSub: "Your password should be 8 to 30 characters long",
        }
      ],
      toastData: {
        message: "",
        type: "success",
      },
      step: 1,
      textSearchPhoneNumber: "",
      phoneNumbersFilter: phoneNumbers,
      phoneValidate: "",
      isCheckReadTC: false,
      isReceiveInformation: false,
      isLoadingButtonRes: false,
      isLoadingButtonRecapt: false,
      isLoadingButtonOtp: false,
      isNewPage: false
    };
  },
  created() {
    this.fetchRecaptcha()
    this.fetchDateCountries()
    this.fetchDateAgo()
    if (this.$store.state.isLoggedIn) {
      this.step = 3
      this.isNewPage = window.location.pathname.includes('registration-completed')
    } else if (!this.$store.state.isLoggedIn && window.location.pathname !== '/register') {
      this.$router.push("/register");
    }
  },
  mounted() {
    this.handleDefaultCountry(this.dataForm);
  },
  beforeDestroy() {   
    this.removeRecaptcha()
  },

  methods: {
    showToast(message, type) {
      this.toastData.message = message;
      this.toastData.type = type;
      this.$bvToast.show("my-toast");
    },
    showDropDow(number) {
      let dropdown = "";
      if (number === 1) {
        dropdown = this.$refs.select_phone_code;
      }
      dropdown.show();
    },
    removeRecaptcha() {
      let recap = document.querySelector(".grecaptcha-badge")
      if(recap && recap.parentNode) {
        recap.parentNode.removeChild(recap)
      }
    },
    onClickGoBack() {
      if(this.step === 2){
        this.step = 1
      }
    },
    redirectRouter(path) {
          if (this.$store.state.is_app) {
              redirectPathApp(path);
          } else {
              this.$router.push(path);
          }
    },
    checkValidPass(value) {
      if(value === true) {
        return 'red'
      } else if(value === false) {
        return 'green'
      } else {
        return ''
      }
    },
    onChangeFilterPhoneNumbers(value) {
      let phoneNumber = [...phoneNumbers];
      if (!isStrEmpty(value)) {
        this.phoneNumbersFilter = filterFullTextSearch(
          phoneNumber,
          value,
          "name",
          "number"
        );
      } else {
        this.phoneNumbersFilter = phoneNumber;
      }
      this.handleValidatePhoneNumber(this.phoneValidate);
    },
    renderUserInfor() {
      let userInfo = JSON.parse(localStorage.getItem("user_info"));
      let type = localStorage.getItem("type_sign_up");
      scriptTag = document.createElement('script');
      scriptTag.textContent = `
          window.dataLayer = window.dataLayer || [];
          dataLayer.push({
              'event': 'sign_up',
              'sign_up_date': '${moment().format('YYYY-MM-DD')}',
              'method': '${type}',
              'user_id': ${userInfo.id},
              'first_name': '${userInfo.first_name || "NA"}',
              'last_name': '${userInfo.last_name || "NA"}',
              'mobile': '+${userInfo.phone || "N/A"}',
              'calling_code': '${userInfo.calling_code || "N/A"}',
              'email': '${userInfo.email || "NA"}',
              'dob': '${userInfo.dob || "NA"}',
              'gender': '${userInfo.gender || "NA"}',
              'nationality': '${userInfo.nationality || "NA"}',
              'country': '${userInfo.country_code || "NA"}',
          });
      `;
      document.head.appendChild(scriptTag);
      scriptTag1 = document.createElement('script');
      scriptTag1.textContent = `
          window.dataLayer = window.dataLayer || [];
          dataLayer.push({
              'user_id': ${userInfo.id},
              'first_name': '${userInfo.first_name || "NA"}',
              'last_name': '${userInfo.last_name || "NA"}',
              'mobile': '+${userInfo.phone || "N/A"}',
              'calling_code': '${userInfo.calling_code || "N/A"}',
              'email': '${userInfo.email || "NA"}',
              'dob': '${userInfo.dob || "NA"}',
              'gender': '${userInfo.gender || "NA"}',
              'nationality': '${userInfo.nationality || "NA"}',
              'country': '${userInfo.country_code || "NA"}',
          });
      `;
      document.head.appendChild(scriptTag1);
    },
    onSubmitForm(paramLoginSocial){
      if (!isStrEmpty(paramLoginSocial?.idToken)) {
        this.isLoadingButtonRes = true
          AuthService.loginByGoogle(
              paramLoginSocial.idToken,
              paramLoginSocial.type
          ).then((res) => {
              if (res.status === 200) {
                localStorage.setItem('type_sign_up', paramLoginSocial.type)
                this.handleFinishSignUp(res, 1);
                this.typeRes = paramLoginSocial.type
              } else {
                  this.showToast(
                      this.getTranslatedText(res.message),
                      "error"
                  );
              }
              this.isLoadingButtonRes = false
          })
          .catch((error) => {
            this.isLoadingButtonRes = false;
            this.showToast(
                this.getTranslatedText('Sign up failed, please try again'),
                "error"
            );
            console.log(error)
          });
      }else if(paramLoginSocial?.type === 'google'){
        this.showToast(
          this.getTranslatedText('Google registration is currently unavailable. Please try again later or use an alternative registration method. We apologize for the inconvenience'),
          "error"
        );
      }else if(paramLoginSocial?.type === 'facebook'){
        this.showToast(
          this.getTranslatedText('Facebook registration is currently unavailable. Please try again later or use an alternative registration method. We apologize for the inconvenience'),
          "error"
        );
      }
    },
    selectPhoneCode(item) {
      this.dataForm.calling_code = item.number;
      this.dataForm.code = item.number;
      this.dataForm.flag = item.flag;
      this.textSearchPhoneNumber = "";
      this.phoneNumbersFilter = phoneNumbers;
      this.handleValidatePhoneNumber(this.phoneValidate);
    },
    handleValidatePhoneNumber(phone) {
      const phoneCallingCode = `${this.dataForm.code}${phone}`;
      const isPossiblePhone = isPossiblePhoneNumber(phoneCallingCode) === true;
      const typePhone = ["MOBILE", "FIXED_LINE_OR_MOBILE"];
      const isValidType = typePhone.includes(
        parseMax(phoneCallingCode)?.getType()
      );
      const isValidNumber = isValidPhoneNumber(phoneCallingCode) === true;
      this.formItem[1].state = isPossiblePhone && isValidNumber && isValidType;
      if (!this.formItem[1].state && phone) {
        this.formItem[1].textInvalid = this.getTranslatedText(
          "incorrect phone number"
        );
      } else {
        this.formItem[1].textInvalid = "please enter mobile number";
      }
    },
    async onValidateForm() {
      let resultValidate = true;
      const letters = /[.,?'"/!;:{}\-—`()[\]]/;
      if (
        isStrEmpty(this.dataForm.full_name) ||
        letters.test(this.dataForm.full_name)
      ) {
        this.formItem[0].state = false;
        let scrollDiv = document.getElementById("register-fullName").offsetTop;
        window.scrollTo({ top: scrollDiv, behavior: "smooth" });
        if (isStrEmpty(this.dataForm.full_name)) {
          this.formItem[0].textInvalid = "Please enter full name";
        } else if (letters.test(this.dataForm.full_name)) {
          this.formItem[0].textInvalid =
            "Please do not enter punctuation marks";
        }
        resultValidate = false;
      }
      if (isStrEmpty(this.dataForm.phone)) {
        this.formItem[1].state = false;
        let scrollDiv = document.getElementById("input-phone").offsetTop;
        window.scrollTo({ top: scrollDiv, behavior: "smooth" });
        resultValidate = false;
      }
      if (!isStrEmpty(this.dataForm.email) && !validateEmail(this.dataForm.email)) {
        this.formItem[2].state = false;
        this.formItem[2].textInvalid = "incorrect email format";
        resultValidate = false;
      } 
      if (!isStrEmpty(this.dataForm.email) && validateEmail(this.dataForm.email)) {
        this.isLoadingButtonRes = true
         try {
            const res = await AuthService.checkMail(this.dataForm.email);
            if (res.status === 200) {
                this.formItem[2].state = true;
                // this.isCheckMail = true
                this.isLoadingButtonRes = false
            }
            if (res.status === 400) {
                let message = "";
                res.errors.forEach((element) => {
                    message += element.message;
                });
                this.formItem[2].state = false;
                this.formItem[2].textInvalid = this.getTranslatedText(message);
                this.isLoadingButtonRes = false
            } else {
                this.formItem[2].state = null;
                this.isLoadingButtonRes = false
            }
        } catch (error) {
            this.formItem[2].textInvalid = this.getTranslatedText(error);
            this.isLoadingButtonRes = false
        }
      }
      if (isStrEmpty(this.dataForm.nationality)) {
        this.formItem[3].state = false;
        this.formItem[3].textInvalid = "Please enter nationality";
        resultValidate = false;
      }
      if (isStrEmpty(this.dataForm.dob) || this.dataForm.dob === 'Invalid date') {
        this.formItem[4].state = false;
        this.textInvalidFeedbackDate = "Please enter date of birth";
        resultValidate = false;
      }
      if (isStrEmpty(this.dataForm.password)) {
        this.formItem[5].state = false;
        let scrollDiv = document.getElementById("input-password").offsetTop;
        window.scrollTo({ top: scrollDiv, behavior: "smooth" });
        resultValidate = false;
      }
      let isContainsLowercase = /^(?=.*[a-z]).*$/;
      let isContainsNumber = /^(?=.*[0-9]).*$/;
      let isContainsSymbol =
          /^(?=.*[~`!@#$%^&*()--+={}[\]|\\:;"'<>,.?/_₹]).*$/;
      let isContainsUppercase = /^(?=.*[A-Z]).*$/;
      // if (!isContainsLowercase.test(this.dataForm.password)) {
      //     this.formItem[5].textInvalid = 'Password must contain At Least 1 Character';
      //     this.formItem[5].state = false;
      //     resultValidate = false;
      // } 
      if(isStrEmpty(this.dataForm.password)) {
          this.formItem[5].textInvalid = 'Please enter password';
          this.formItem[5].state = false;
          resultValidate = false;
      }
      else if (this.dataForm.password.length < LIMIT_PASSWORD || this.dataForm.password.length > 30) {
          this.formItem[5].textInvalid = '';
          this.formItem[5].state = false;
          resultValidate = false;
      }
      else if (!isContainsNumber.test(this.dataForm.password)) {
          this.formItem[5].textInvalid = '';
          this.formItem[5].state = false;
          resultValidate = false;
      }
      else if (!isContainsSymbol.test(this.dataForm.password)) {
          this.formItem[5].textInvalid = '';
          this.formItem[5].state = false;
          resultValidate = false;
      }
      else if (!isContainsUppercase.test(this.dataForm.password)) {
          this.formItem[5].textInvalid = '';
          this.formItem[5].state = false;
          resultValidate = false;
      }
      else {
          this.formItem[5].textInvalid = '';
          this.formItem[5].state = null;
      }
      resultValidate = this.formItem.every((e) => e.state !== false);
      return resultValidate;
    },
    fetchRecaptcha() {
      if(this.recaptchaType !== 'v2') {
        scriptTag2 = document.createElement("script");
        scriptTag2.src =`https://www.google.com/recaptcha/enterprise.js?render=${this.sitekey}`
        document.head.appendChild(scriptTag2);
      }
    },
    fetchDateAgo() {
        const nowYear = moment().format("YYYY");
        const nowMonthDate = moment().format("MM-DD");
        let yearAgo = `${parseInt(nowYear) - 18}-${nowMonthDate}`;
        this.maxDate = yearAgo;
        this.maxYearBirth = `${parseInt(nowYear) - 18}`;
    },
    updateDate(date) {
      let dateFormat = date.split(".");
      if(!isStrEmpty(dateFormat[0]) && dateFormat[0] !== '0' && !isStrEmpty(dateFormat[1]) && !isStrEmpty(dateFormat[2])) {
          let valueConvert = `${dateFormat[2]}/${dateFormat[1]}/${dateFormat[0]}`;
          let dateFormatUTC = moment(valueConvert).format("MM/DD/YYYY");
          if (
            !isStrEmpty(this.textInvalidFeedbackDate) &&
            moment(dateFormatUTC).format("YYYY-MM-DD") <=
            moment(this.maxDate).format("YYYY-MM-DD")
          ) {
            this.textInvalidFeedbackDate = "";
          }
        this.textInvalidFeedbackDate = "";
        this.formItem[4].state = null;
        this.dataForm.dob = dateFormatUTC;
      }
    },
    async fetchDateCountries() {
      try {
          const resp = await CountriesService.getCountries();
          if (resp.status === 200) {
              const countries = resp.data.map(({code, name}) => ({
                  value: code,
                  text: name,
              }));
              this.formItem[3].options = countries;
        }
      } catch (error) {
          console.log(error);
      }
    },
    async sendOTPForPhone(type, responseRecaptcha) {
      this.isLoadingButtonRecapt = true
      let token
       if(this.recaptchaType === 'v2') {
          token = grecaptcha.enterprise.getResponse();
       } else {
          token = responseRecaptcha
      }
      if(!token) {
          this.showToast(this.getTranslatedText('recaptcha is required'), "error");
          this.isLoadingButtonRes = false
          this.isLoadingButtonRecapt = false
          return false
      }
       AuthService.sendOTPToPhone(
          "register",
          this.dataForm.phone,
          this.dataForm.calling_code.replace(/\+/g, ""),
          "",
          token,
          'signup',
          false,
      ).then((res) => {
          if (res.status === 200) {
              this.isLoadingButtonRecapt = false
              this.$bvModal.hide("modalRecaptchaSignup");
              this.step = 2;
              this.showToast(this.getTranslatedText(res.message), "success");
          } else {
              this.isLoadingButtonRecapt = false
              let message = "";
              !isArrayEmpty(res.errors)
              ? res.errors.forEach((element) => {
                  message += element.message;
              }) : (message = res.message);
              this.$bvModal.hide("modalRecaptchaSignup");
              this.showToast(this.getTranslatedText(message), "error");
          }
      });
    },
    async getTokenRecaptcha(type) {
      if(this.recaptchaType === 'v2') {
        this.$nextTick(() => {
            grecaptcha.enterprise.ready(() => {
                try {
                    grecaptcha.enterprise.render('recaptcha-signup', {
                        sitekey: this.sitekey,
                        action: 'signup',
                        hl: localStorage.getItem("locale") || 'en'
                    })
                } catch (err) {
                    console.log(err)
                }
                })
            }
        )
        this.$bvModal.show("modalRecaptchaSignup");
      } else {
        const token = await grecaptcha.enterprise.execute(this.sitekey, {action: 'signup'})        
        this.sendOTPForPhone(type, token)
      }
    },
    async checkSubmitPhone() {
      if (this.dataForm.phone !== "" && !isStrEmpty(this.dataForm.calling_code)) {
        this.isLoadingButtonRes = true
        try {
            const res = await AuthService.checkPhone(
              this.dataForm.calling_code, this.dataForm.phone);
            if (res.status === 200) {
                this.formItem[1].state = true;
                this.isLoadingButtonRes = false
                this.getTokenRecaptcha()
            }

            if (res.status === 400) {
                let message = "";
                res.errors.forEach((element) => {
                    message += element.message;
                });
                this.formItem[1].state = false;
                this.formItem[1].textInvalid = message;
                this.isLoadingButtonRes = false
            } else {
                this.formItem[1].state = null;
                this.isLoadingButtonRes = false
            }
          } catch (error) {
              this.formItem[1].textInvalid = error;
          }
        }
    },
    async onSubmitRegister() {
      const validate = await this.onValidateForm();
      if (!validate) return;
      if (!this.isCheckReadTC) {
        this.showToast(
          this.getTranslatedText(
            'Please select check box "I confirm that I have read and accepted the Terms & Conditions and Privacy Policy.*"'
          ),
          "error"
        );
        return;
      }
      await this.checkSubmitPhone();
    },
    async onSubmitSignUp(data) {
      this.isLoadingButtonOtp = true
      let utm = JSON.parse(localStorage.getItem('utm-paramaters'))
      let {utm_campaign, utm_content, utm_medium, utm_source, utm_term} = utm ? utm : {}
      let platform_slug = this.$store.state.is_app ? 'app' : 'web'
      let { phone, calling_code, password, full_name, dob, nationality, gender, email } = this.dataForm;
        calling_code = calling_code.replace(/\+/g, "");
        const urlApi = AuthService.apiCheckOTPByPhone(
            data.code,
            "register",
            phone,
            calling_code
            );
        try {
          const resp = await urlApi;
          if (resp.status === 200) {
            const res = await AuthService.register(
              "",
              "",
              full_name,
              calling_code,
              phone,
              password,
              data.code,
              dob,
              nationality,
              gender,
              platform_slug,
              email,
              utm_campaign,
              utm_content,
              utm_medium,
              utm_source,
              utm_term
            );
            if (res.status === 200) {
              localStorage.setItem('type_sign_up', 'mobile')
              this.handleFinishSignUp(res);
              this.isLoadingButtonOtp = false
            } else {
              this.isLoadingButtonOtp = false;
              if (!isArrayEmpty(res.errors)) {
                this.showToast(
                  this.getTranslatedText(res.errors[0].message),
                  "error"
                );
              } else {
                this.showToast(this.getTranslatedText(res.message), "error");
              }
            }
          } else {
            let message = "";
            !isArrayEmpty(resp.errors)
                ? resp.errors.forEach((element) => {
                    message += element.message;
                })
                : (message = resp.message);

            this.showToast(message, "error");
            this.isLoadingButtonOtp = false;
          }
        } catch (error) {
          this.isLoadingButtonOtp = false;
          console.log(error);
        }
    },
    async handleFinishSignUp(res, typeSignUp = 0){
        const logged = !res?.data?.is_new_user;
        if(typeSignUp === 1 && logged){
          this.onFinishLogin();
          return;
        }
        this.step = 3;
        window.location.href = '/registration-completed'
        this.isNewPage = window.location.pathname.includes('registration-completed')
        this.renderUserInfor()
        window.scrollTo({ top: 0 });
        this.isLoadingButtonRes = false;
        let list_block_tmp = !isStrEmpty(
          localStorage.getItem("list_block_tmp")
        )
          ? JSON.parse(localStorage.getItem("list_block_tmp"))
          : [];

        if (!isArrayEmpty(list_block_tmp)) {
          let amount = 0;

          list_block_tmp.map((x) => {
            amount += x.date.length;
          });

          let param = {
            cart_data: list_block_tmp,
            items: list_block_tmp.length,
            amount,
          };

          const respSaveCart = await AuthService.saveListCart(param);
          if (respSaveCart.status === 200) {
            localStorage.setItem(
              "list_block_tmp",
              JSON.stringify(list_block_tmp)
            );
            localStorage.setItem(
              "list_block",
              JSON.stringify(list_block_tmp)
            );
            localStorage.setItem("total_items", param.amount);
          }
        } else {
          localStorage.setItem("isRegister", true);
          localStorage.setItem("total_items", 0);
        }
        await this.$store.dispatch("setBalance");
      },
    updateFormData(item) {
      const { value, name } = item;
      if (name === 'email') {
        this.dataForm.email = value;
        if (!isStrEmpty(value) && !validateEmail(value)) {
          this.formItem[2].state = false;
          this.formItem[2].textInvalid = this.getTranslatedText(
              "incorrect email format"
          );
        } else {
          this.formItem[2].state = true;
          this.formItem[2].textInvalid = "";
        }
      }
      if (name === 'nationality') {
        this.dataForm.nationality = value;
        if(!isStrEmpty(this.dataForm.nationality)) {
            this.formItem[3].state = null;
            this.formItem[3].textInvalid = "";
        }
      }
      if (name === "full_name") {
        this.dataForm.full_name = value;
        if (this.dataForm.full_name.length > 50) {
          this.formItem[0].state = false;
          this.formItem[0].textInvalid =
            "The full name should not greater than 50 characters";
        } else {
          this.formItem[0].state = null;
          this.formItem[0].textInvalid = "";
        }
      }
      if (name === "phone") {
        this.phoneValidate = value;
        this.dataForm.phone = value;
        this.handleValidatePhoneNumber(value);
      }
      if (name === "password") {
        this.dataForm.password = value;
        if(isStrEmpty(this.dataForm.password)) {
          this.formItem[5].textInvalid = 'Please enter password';
          this.formItem[5].state = false;
        } else {
          this.formItem[5].state = null;
          this.formItem[5].textInvalid = "";
        }
        let isContainsUppercase = /^(?=.*[A-Z]).*$/;
        let isContainsNumber = /^(?=.*[0-9]).*$/;
        let isContainsSymbol =
          /^(?=.*[~`!@#$%^&*()--+={}[\]|\\:;"'<>,.?/_₹]).*$/;
        if (this.dataForm.password.length < 8 || this.dataForm.password.length > 30) {
          this.formItem[5].state = false;
          // this.formItem[5].textInvalid =
          //   "Your password should be 8 to 30 characters long";
          this.isInvalidLengthPass = true;
        } else {
          this.formItem[5].state = null;
          this.formItem[5].textInvalid = "";
          this.isInvalidLengthPass = false
        }
        if(!isContainsUppercase.test(this.dataForm.password)) {
          this.formItem[5].state = false;
          this.isInvalidCapitalPass = true;
        } else {
          this.formItem[5].state = null;
          this.formItem[5].textInvalid = "";
          this.isInvalidCapitalPass = false;
        }
        if(!isContainsNumber.test(this.dataForm.password) || !isContainsSymbol.test(this.dataForm.password)) {
          this.formItem[5].state = false;
          this.isInvalidSpecialChar = true;
        } else {
          this.formItem[5].state = null;
          this.formItem[5].textInvalid = "";
          this.isInvalidSpecialChar = false
        }
      }
    },
    async onFinishLogin() {
      let listGame = !isStrEmpty(localStorage.getItem("list_games"))
          ? JSON.parse(localStorage.getItem("list_games"))
          : [];
      let totalCart = !isStrEmpty(localStorage.getItem("total_items"))
          ? parseInt(localStorage.getItem("total_items"))
          : 0;

      if (isArrayEmpty(listGame)) {
          window.location.href = "/";
          return false;
      }

      try {
          const resp = await AuthService.getListCart();
          if (resp.status === 200) {
              localStorage.setItem("isLogin", true);

              let list_block_tmp =
                  totalCart > 0
                      ? JSON.parse(localStorage.getItem("list_block_tmp"))
                      : [];
              let list_block_api =
                  parseInt(resp.data?.amount) > 0
                      ? JSON.parse(resp.data.cart_data)
                      : [];

              if (!isArrayEmpty(list_block_tmp)) {
                  list_block_tmp = list_block_api.concat(list_block_tmp);
                  list_block_tmp = [...new Set(list_block_tmp)];

                  let amount = 0;

                  list_block_tmp.map((x) => {
                      amount += x.date.length;
                  });

                  let param = {
                      cart_data: list_block_tmp,
                      items: list_block_tmp.length,
                      amount,
                  };

                  const respSaveCart = await AuthService.saveListCart(
                      param
                  );

                  if (respSaveCart.status === 200) {
                      this.$store.dispatch(
                          "onChangeAnountCart",
                          parseInt(param.amount)
                      );
                      localStorage.setItem(
                          "list_block_tmp",
                          JSON.stringify(list_block_tmp)
                      );
                      localStorage.setItem(
                          "list_block",
                          JSON.stringify(list_block_tmp)
                      );
                      localStorage.setItem("total_items", param.amount);
                      window.location.href = "/";
                  }
              } else {
                  let amount = resp?.data?.amount
                      ? parseInt(resp.data.amount)
                      : 0;

                  localStorage.setItem(
                      "list_block_tmp",
                      JSON.stringify(list_block_api)
                  );
                  localStorage.setItem(
                      "list_block",
                      JSON.stringify(list_block_api)
                  );
                  localStorage.setItem("total_items", amount);

                  window.location.href = "/";
              }
          } else {
              window.location.href = "/";
          }
        this.isLoadingButtonRes = false;
      } catch (error) {
          this.isLoadingButtonRes = false;
          this.showToast(error, "error");
      }
  },
    onToggleShowPassword(name) {
      let index = this.formItem.findIndex((x) => x.name === name);
      if (index !== -1) {
        this.formItem[index].typeShow =
          this.formItem[index].typeShow === "password" ? "text" : "password";
      }
    },
  },
};
</script>
<style
    scoped
    lang="scss"
    src="@/assets/scss/mobile/authenticate/_sign-up.scss"
/>
